import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy"
export default class extends Controller {
  static targets = ["district", "street", "house", "flat", "extension", "entrance", "floor"];
  static values = { adminPermission: Boolean }

  connect() {
    this.adminPermissionValue = this.element.dataset.copyAdminPermissionValue === "true";
  }

  async copyText(event) {
    let textToCopy;
    if (this.adminPermissionValue) {
      textToCopy = [
        this.streetTarget?.selectedOptions[0]?.text || "",
        "буд. " + this.houseTarget?.selectedOptions[0]?.text || "",
        "кв. " + (this.flatTarget?.selectedOptions[0]?.text || "") + (this.extensionTarget?.value || ""),
        "під'їзд " + this.entranceTarget?.value || "",
        "поверх " + this.floorTarget?.value || "",
      ].filter(text => text.trim() !== "").join(", ");
    } else {
      textToCopy = [
        this.streetTarget?.selectedOptions[0]?.text || "",
        "буд. " + this.houseTarget?.selectedOptions[0]?.text || "",
        "кв. " + (this.flatTarget?.selectedOptions[0]?.text || "") + (this.extensionTarget?.value || "")
      ].filter(text => text.trim() !== "").join(", ")
    }

    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";
        document.body.prepend(textArea);
        textArea.select();
        document.execCommand('copy');
        textArea.remove();
      }

      const currentCursor = event.target.style.cursor;

      event.target.style.cursor = "copy";

      setTimeout(() => {
        event.target.style.cursor = currentCursor;
      }, 1000);
    } catch (err) {
      console.error("Помилка копіювання: ", err);
    }
  }
}
