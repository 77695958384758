import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="role-permission-field"
export default class extends Controller {
  static targets = [
    "template",
    "rolePermissions"
  ];

  addRow(event) {
    event.preventDefault();
    const template = this.templateTarget.content.cloneNode(true);

    const uuid = Math.floor(Date.now() * Math.random());

    template.querySelectorAll("*").forEach((node) => {
      if (node.hasAttributes()) {
        Array.from(node.attributes).forEach((attr) => {
          if (attr.value.includes("{{UUID}}")) {
            attr.value = attr.value.replaceAll("{{UUID}}", uuid);
          }
        });
      }
      if (node.textContent.includes("{{UUID}}")) {
        node.textContent = node.textContent.replaceAll("{{UUID}}", uuid);
      }
    });

    this.rolePermissionsTarget.appendChild(template);
  }
}
